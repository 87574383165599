<style lang="less" scoped>
  .report-nucleic-page {
    flex: 1;
    background-color: #FFF;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .file-area {
    height: 100px;
    cursor: pointer;
    border: 1px dashed #999;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 800;
    color: #657180;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #008fff;
      color: #008fff;
    }
    &.dragenter::after {
      text-shadow: 0 0 1px #000;
      content: attr(status);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      position: absolute;
      color: #FFF;
      border: 1px dotted #EEE;
      background-color: rgba(47, 91, 234, .5);
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .search {
    justify-content: space-between;
    display: flex;
    margin-top: 10px;
    border-bottom: 1px solid #EEE;
  }
  .data-list {
    position: relative;
    flex: 1;
    overflow: auto;
  }
  .header {
    justify-content: space-between;
    display: flex;align-items: center;margin-bottom: 10px;
    .actions {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > * + * {
        margin-left: 10px;
      }
    }
  }
  .data-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    background-color: #FFF;
    padding: 0;
    box-sizing: border-box;
    .item {
      width: 25%;
      padding: 5px;
      box-sizing: border-box;
      .wrap {
        padding-bottom: 5px;
        cursor: pointer;
        border-radius: 5px;
        background-color: #FFF;
        position: relative;
        border: 1px solid #EEE;
        transition: all .3s;
        .status {
          padding: 1px 3px;
          font-size: 12px !important;
          transition: all .3s;
          border-radius: 5px;
          background-color: #b8b4b4;
          color: #FFF;
        }
        .title {
          font-size: 18px !important;
          transition: all .3s;
          background-color: #F8F8F8;
          border-bottom: 1px solid #EEE;
          padding: 5px 10px;
        }
      }
      &.normal {
        .wrap {
          .status {
            color: #FFF;
            background-color: rgb(23, 211, 105);
          }
        }
      }
      &.alarm {
        .wrap {
          .status {
            color: #FFF;
            background-color: rgb(251, 64, 64);
          }
        }
      }
      &.checked {
        .wrap {
          background-color: #e8e8e8;
          .title {
            background-color: #d7d7d7;
            border-bottom-color: transparent;
          }
        }
      }
      .field, .value {
        font-size: 15px;
      }
      .field {
        margin-right: 10px;
        color: #657180;
      }
      .value {
        color: #000;
      }
      .row-between {
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & + & {
          margin-top: 5px;
        }
      }
      .btn {
        cursor: pointer;
        transition: all .3s;
        color: #008fff;
        &:hover {
          color: #0067b6;
        }
      }
    }
  }
  .split {
    text-align: center;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 800;
    color: #657180;
    &:before {
      flex: 1;
    }
    &::after {
      flex: 4;
    }
    &:before, &::after {
      margin: 0 10px;
      height: 1px;
      content: '';
      background-color: #EEE;
    }
  }
</style>

<style>
@import url('/static/document/nucleic.css');
</style>

<template>
  <div class="report-nucleic-page" v-loadingx="loading.load">
    <div class="header">
      <fm-title style="height: unset;min-height: unset;" title-text="核酸报告"></fm-title>
      <div class="actions">
        <span v-if="importModes && Array.isArray(importModes) && importModes.length > 1" style="font-size: 15px;">请选择导入模式：</span>
        <fm-radio-group v-if="importModes && Array.isArray(importModes) && importModes.length > 1" v-model="importMode">
          <fm-radio v-for="item in importModes" :key="item.value" :value="item.value" :label="item.label"></fm-radio>
        </fm-radio-group>
        <fm-btn v-if="sysEnv.env === 'hrms_thjkzx'" @click="status.export = true">数据导出</fm-btn>
        <fm-btn v-if="$authFunsProxy.result && sysEnv.env === 'hrms_thjkzx'" @click="openResultUpdate">结果发布</fm-btn>
        <fm-btn v-if="$authFunsProxy.result && sysEnv.env === 'hrms_thjkzx'" @click="openJsrqUpdate">样品接收</fm-btn>
        <fm-btn @click="openForm" v-if="$authFunsProxy.update || $authFunsProxy.result">新增</fm-btn>
      </div>
    </div>
    <div class="file-area" v-if="$authFunsProxy.result || $authFunsProxy.update" ref="area" :class="{dragenter: dragenter}"
      @click="$refs.input.click()"
      @drop="fileDrop"
      @dragenter="onDragenter"
      @dragover="onDragover"
      @dragend="onDragend"
    >点击或拖拽文件至此上传检测数据</div>
    <input type="file" ref="input" multiple style="display: none;" @change="readFile()" />
    <div class="search">
      <fm-form inline style="margin-right: 10px;" label-alone label-align="left">
        <fm-form-item style="margin-bottom: 10px;" label="字段">
          <fm-input-new v-model="search.value" clearable style="width: 280px;" placeholder="姓名/身份证/样本编号/系统编号搜索">
            <fm-select style="width: 100px" slot="prepend" v-model="search.type">
              <fm-option label="姓名" value="name"></fm-option>
              <fm-option label="身份证" value="idno"></fm-option>
              <fm-option label="样本编号" value="ybid"></fm-option>
              <fm-option label="系统编号" value="code"></fm-option>
            </fm-select>
          </fm-input-new>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 10px;" label="检测时间">
          <fm-date-picker type="date" clearable v-model="search.date" style="width: 140px;" placeholder="请选择检测日期"></fm-date-picker>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 10px;" label="采样日期">
          <DatePicker show-week-numbers clearable type="daterange" v-model="search.cyrq" :options="dateOptions" placeholder="请选择采样日期" style="width: 210px"></DatePicker>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 10px;" label="采样单位" v-loadingx="loading.cydd">
          <fm-select v-model="search.cydd" filterable clearable style="width: 155px;">
            <fm-option v-for="item in cyddList" :key="item" :label="item" :value="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 10px;" label="检测结果">
          <fm-select v-model="search.result" filterable clearable style="width: 100px;">
            <fm-option label="阳性" value="阳性"></fm-option>
            <fm-option label="阴性" value="阴性"></fm-option>
            <fm-option label="未检测" value="未检测"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 10px;" label="样品是否已接收">
          <fm-select v-model="search.nullJsrq" filterable clearable style="width: 130px;">
            <fm-option label="样本已接收" value="0"></fm-option>
            <fm-option label="样本未接收" value="1"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 10px;" :label="'-'">
          <fm-btn @click="onSearch">搜索</fm-btn>
        </fm-form-item>
      </fm-form>
    </div>
    <div class="data-list" ref="list" v-loadingx="loading.get">
      <img v-if="!dataList.length" src="/static/images/no-data.png" style="width: 200px;display: block;;margin: 0 auto;margin-top: 20px;" />
      <div class="items">
        <div class="item" :class="{
            alarm: item.result === '阳性',
            normal: item.result === '阴性',
            checked: checked.includes(i)
          }"
          @click="itemChecked(i, item)"
          v-for="(item, i) in dataList"
          :key="i + '-' + item.name">
          <div class="wrap">
            <div class="row-between title">
              <div>
                <span class="field">姓名:</span>
                <span class="value">{{item.name || '-'}}</span>
              </div>
              <div>
                <span class="field">年龄:</span>
                <span class="value">{{item.age || '-'}}</span>
              </div>
              <div>
                <span class="field">性别:</span>
                <span class="value">{{item.gender || '-'}}</span>
              </div>
              <div class="status">{{item.result || '未填报'}}</div>
            </div>
            <div class="row-between">
              <span class="field">身份证:</span>
              <span class="value">{{item.idno}}</span>
            </div>
            <div class="row-between">
              <span class="field">样本编号:</span>
              <span class="value">{{item.ybid || '-'}}</span>
            </div>
            <div class="row-between">
              <span class="field">系统编码:</span>
              <span class="value">{{item.code || '-'}}</span>
            </div>
            <div class="row-between">
              <span class="field">采样单位:</span>
              <span class="value">{{item.cydd || '-'}}</span>
            </div>
            <div class="row-between">
              <span class="field">采样日期:</span>
              <span class="value">{{(item.cyrq || '-').substr(0, 10)}}</span>
            </div>
            <div class="row-between">
              <span class="field">接收日期:</span>
              <span class="value" :style="{
                color: item.jsrq ? '#000' : 'red'
              }">{{item.jsrq ? ((item.jsrq || '-').substr(0, 10)) : '待接收'}}</span>
            </div>
            <div class="row-between">
              <span class="field">检测时间:</span>
              <span class="value" :style="{
                color: item.jcsj ? '#000' : '#ff8200'
              }">{{item.jcsj ? ((item.jcsj || '-').substr(0)) : '待检测'}}</span>
            </div>
            <div class="row-between">
              <div>
                <span clas="field">序号：</span>
                <span clas="value">{{item.id}}</span>
              </div>
              <span class="btn" @click.stop="openEdit(item)">修改</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-top: 10px;display: flex;justify-content: space-between;align-items: center;">
      <div style="display: flex;align-items: center;">
        <fm-page show-total show-elevator @change="pageChange" :page-size="size" :total="total" :current="page"></fm-page>
        <div style="margin-left: 10px;">
          <span>每页条数：</span>
          <el-input-number v-model="size" @change="pageSizeChange" :min="10" size="small"></el-input-number>
        </div>
      </div>
      <div class="data-action">
        <template v-if="dataList.length && ($authFunsProxy.print || $authFunsProxy.del)">
          <div>
            <template v-if="checked.length">
              <fm-btn v-if="$authFunsProxy.print" @click="print">确定打印</fm-btn>
              <fm-btn v-if="$authFunsProxy.del" @click="del">删除已选</fm-btn>
              <fm-btn v-if="$authFunsProxy.result && sysEnv.env === 'hrms_thjkzx'" @click="setResultUpdate">批量结果发布</fm-btn>
              <fm-btn v-if="$authFunsProxy.result && sysEnv.env === 'hrms_thjkzx'" @click="setJsrqUpdate">批量样品接收</fm-btn>
            </template>
            <fm-btn @click="checkAll">全选</fm-btn>
            <fm-btn @click="checkReverse">反选</fm-btn>
          </div>
          <span style="margin-left: 10px;">已选：{{checked.length}}条数据</span>
        </template>
      </div>
    </div>
    <iframe style="display: none;" ref="iframe"></iframe>
    <!-- <iframe ref="iframe"></iframe> -->
    <!-- 临时组件，仅用于上传签名和印章图片 -->
    <folders v-if="status.folders" :funs="{mkdir: status.folders, add: status.folders, get: status.folders, upload: status.folders, del: status.folders, down: status.folders}" />
    <fm-modal v-model="status.form" :mask-closable="false" width="1000px" :title="chooseData && chooseData.id ? ('序号 - ' + chooseData.id + ' - 修改 - 导入时间: ' + chooseData.createTime) : '新增记录'">
      <fm-form :inline="3" v-if="chooseData" label-width="120px">
        <fm-form-item :label="item.label" v-for="item in formParams" :key="item.key">
          <fm-input-new v-if="item.type === 'input'" :placeholder="item.placeholder || ('请输入' + item.label)" v-model="chooseData[item.key]"></fm-input-new>
          <fm-radio-group v-else-if="item.type === 'radio'" v-model="chooseData[item.key]">
            <fm-radio v-for="radio in item.radios" :key="radio.value" :value="radio.value" :label="radio.label"></fm-radio>
          </fm-radio-group>
          <fm-select v-else-if="item.type === 'select'" v-model="chooseData[item.key]" :placeholder="item.placeholder || ('请选择' + item.label)" filterable clearable>
            <fm-option v-for="option in item.options" :key="option.value" :label="option.label" :value="option.value"></fm-option>
          </fm-select>
          <fm-date-picker v-else-if="item.type === 'datetime'" style="flex: 1;" type="datetime" clearable v-model="chooseData[item.key]" :placeholder="item.placeholder || ('请输入' + item.label)"></fm-date-picker>
          <fm-date-picker v-else-if="item.type === 'date'" style="flex: 1;" type="date" clearable v-model="chooseData[item.key]" :placeholder="item.placeholder || ('请输入' + item.label)"></fm-date-picker>
          <fm-input-number v-else-if="item.type === 'number'" style="flex: 1;" v-model="chooseData[item.key]" :max="item.max" :min="item.min" :placeholder="item.placeholder || ('请输入' + item.label)"></fm-input-number>
        </fm-form-item>
      </fm-form>
      <div v-if="chooseData">
        <fm-btn :disabled="!$authFunsProxy.result && chooseData.id && chooseData.result ? true : false" @click="save">{{chooseData.id ? '保存' : '新增'}}</fm-btn>
      </div>
    </fm-modal>
    <fm-modal v-model="status.overlay" :mask-closable="false" width="1000px">
      <fm-form label-width="120px">
        <fm-form-item :label="item.label + ':'" v-for="item in overlay.formParams" :key="item.key">
          <fm-input-new v-if="item.type === 'input'" :placeholder="item.placeholder || ('请输入' + item.label)" v-model="overlay.formData[item.key]"></fm-input-new>
          <fm-radio-group v-else-if="item.type === 'radio'" v-model="overlay.formData[item.key]">
            <fm-radio v-for="radio in item.radios" :key="radio.value" :value="radio.value" :label="radio.label"></fm-radio>
          </fm-radio-group>
          <fm-select v-else-if="item.type === 'select'" v-model="overlay.formData[item.key]" :placeholder="item.placeholder || ('请选择' + item.label)" filterable clearable>
            <fm-option v-for="option in item.options" :key="option.value" :label="option.label" :value="option.value"></fm-option>
          </fm-select>
          <fm-date-picker v-else-if="item.type === 'datetime'" style="flex: 1;" type="datetime" clearable v-model="overlay.formData[item.key]" :placeholder="item.placeholder || ('请输入' + item.label)"></fm-date-picker>
          <fm-date-picker v-else-if="item.type === 'date'" style="flex: 1;" type="date" clearable v-model="overlay.formData[item.key]" :placeholder="item.placeholder || ('请输入' + item.label)"></fm-date-picker>
          <fm-input-number v-else-if="item.type === 'number'" style="flex: 1;" v-model="overlay.formData[item.key]" :max="item.max" :min="item.min" :placeholder="item.placeholder || ('请输入' + item.label)"></fm-input-number>
        </fm-form-item>
        <fm-form-item>
          <fm-btn v-loadingx="loading.result" @click="overlayUpdate">确定导入</fm-btn>
        </fm-form-item>
      </fm-form>
    </fm-modal>
    <fm-modal v-model="status.result" :mask-closable="false" width="600px" title="结果发布">
      <fm-form label-width="120px">
        <fm-form-item label="序号：" v-if="resultFormData.ids">
          <fm-input-new v-model="resultFormData.ids" readonly />
        </fm-form-item>
        <template v-for="item in resultFormParams">
          <div class="split" :key="item.label" v-if="item.type === 'split'">{{item.label}}</div>
          <fm-form-item v-else :label="item.label + ':'" :key="item.key">
            <fm-input-new v-if="item.type === 'input'" :placeholder="item.placeholder || ('请输入' + item.label)" v-model="resultFormData[item.key]"></fm-input-new>
            <fm-radio-group v-else-if="item.type === 'radio'" v-model="resultFormData[item.key]">
              <fm-radio v-for="radio in item.radios" :key="radio.value" :value="radio.value" :label="radio.label"></fm-radio>
            </fm-radio-group>
            <fm-select v-else-if="item.type === 'select'" v-model="resultFormData[item.key]" :placeholder="item.placeholder || ('请选择' + item.label)" filterable clearable>
              <fm-option v-for="option in item.options" :key="option.value" :label="option.label" :value="option.value"></fm-option>
            </fm-select>
            <fm-date-picker v-else-if="item.type === 'datetime'" style="flex: 1;" type="datetime" clearable v-model="resultFormData[item.key]" :placeholder="item.placeholder || ('请输入' + item.label)"></fm-date-picker>
            <fm-date-picker v-else-if="item.type === 'date'" style="flex: 1;" type="date" clearable v-model="resultFormData[item.key]" :placeholder="item.placeholder || ('请输入' + item.label)"></fm-date-picker>
            <fm-input-number v-else-if="item.type === 'number'" style="flex: 1;" v-model="resultFormData[item.key]" :max="item.max" :min="item.min" :placeholder="item.placeholder || ('请输入' + item.label)"></fm-input-number>
          </fm-form-item>
        </template>
        <fm-form-item>
          <fm-btn v-loadingx="loading.result" @click="resultUpdate">确定发布</fm-btn>
        </fm-form-item>
      </fm-form>
    </fm-modal>
    <fm-modal v-model="status.export" :mask-closable="false" width="350px" title="数据导出">
      <fm-form>
        <fm-form-item label="采样日期">
          <DatePicker show-week-numbers clearable type="daterange" v-model="exportData.cyrq" :options="dateOptions" placeholder="请选择采样日期" style="width: 210px"></DatePicker>
        </fm-form-item>
        <fm-form-item>
          <fm-btn @click="confirmExportData" v-loadingx="loading.export">确定导出</fm-btn>
        </fm-form-item>
      </fm-form>
    </fm-modal>
    <fm-modal v-model="status.jsrq" :mask-closable="false" width="600px" title="样品接收">
      <fm-form label-width="120px">
        <fm-form-item label="序号：" v-if="jsrqFormData.ids">
          <fm-input-new v-model="jsrqFormData.ids" readonly />
        </fm-form-item>
        <template v-for="item in jsrqFormParams">
          <div class="split" :key="item.label" v-if="item.type === 'split'">{{item.label}}</div>
          <fm-form-item v-else :label="item.label + ':'" :key="item.key">
            <fm-input-new v-if="item.type === 'input'" :placeholder="item.placeholder || ('请输入' + item.label)" v-model="jsrqFormData[item.key]"></fm-input-new>
            <fm-radio-group v-else-if="item.type === 'radio'" v-model="jsrqFormData[item.key]">
              <fm-radio v-for="radio in item.radios" :key="radio.value" :value="radio.value" :label="radio.label"></fm-radio>
            </fm-radio-group>
            <fm-select v-else-if="item.type === 'select'" v-model="jsrqFormData[item.key]" :placeholder="item.placeholder || ('请选择' + item.label)" filterable clearable>
              <fm-option v-for="option in item.options" :key="option.value" :label="option.label" :value="option.value"></fm-option>
            </fm-select>
            <fm-date-picker v-else-if="item.type === 'datetime'" style="flex: 1;" type="datetime" clearable v-model="jsrqFormData[item.key]" :placeholder="item.placeholder || ('请输入' + item.label)"></fm-date-picker>
            <fm-date-picker v-else-if="item.type === 'date'" style="flex: 1;" type="date" clearable v-model="jsrqFormData[item.key]" :placeholder="item.placeholder || ('请输入' + item.label)"></fm-date-picker>
            <fm-input-number v-else-if="item.type === 'number'" style="flex: 1;" v-model="jsrqFormData[item.key]" :max="item.max" :min="item.min" :placeholder="item.placeholder || ('请输入' + item.label)"></fm-input-number>
          </fm-form-item>
        </template>
        <fm-form-item>
          <fm-btn v-loadingx="loading.jsrq" @click="jsrqUpdate">处理接收</fm-btn>
        </fm-form-item>
      </fm-form>
    </fm-modal>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { isExcel } from '@/components/base/file/lib'
import Folders from '@/components/base/file/index'
import { fileRequest, nucleicReportRequest, orgRequest } from '@/api'
import { config } from './lib'
import sysEnv from '@/env/env'
import { createWorkbook, bookAppendSheet, aoaToSheet, downloadExcelWithStyle, setWorkbookBaseStyle, setWorksheetCellStyle, Colspan, colspan } from '@/components/excel/lib'
import { XDate } from '@/components/fmui/components/FmDatePicker/lib'

let signature = []
let overlayHook = {}

const shortcuts = Object.freeze([
  { 
    text: '今天',
    value () {
      return [new Date(new XDate().format('Y-M-D 00:00:00')), new Date(new XDate().compute('d', 1).format('Y-M-D 00:00:00'))]
    }
  },
  { 
    text: '昨天',
    value () {
      return [new Date(new XDate().compute('d', -1).format('Y-M-D 00:00:00')), new Date(new XDate().format('Y-M-D 00:00:00'))]
    }
  },
  {
    text: '近三天',
    value () {
      return [new Date(new XDate().compute('d', -3).format('Y-M-D 00:00:00')), new Date(new XDate().format('Y-M-D 00:00:00'))]
    }
  },
  { 
    text: '近七天',
    value () {
      return [new Date(new XDate().compute('d', -7).format('Y-M-D 00:00:00')), new Date(new XDate().format('Y-M-D 00:00:00'))]
    }
  },
  { 
    text: '近十四天',
    value () {
      return [new Date(new XDate().compute('d', -14).format('Y-M-D 00:00:00')), new Date(new XDate().format('Y-M-D 00:00:00'))]
    }
  }
])

export default {
  components: { Folders },
  data () {
    return {
      chooseData: null,
      dragenter: false,
      loading: {
        load: false,
        get: false,
        cydd: false,
        result: false,
        export: false,
        jsrq: false
      },
      dataList: [],
      cyddList: [],
      search: {
        type: 'idno',
        value: null,
        date: null,
        cydd: null,
        cyrq: [new XDate().compute('d', -1), new XDate()],
        result: null,
        nullJsrq: '0'
      },
      page: 1,
      total: 0,
      size: Number(localStorage.getItem('report-nucleic-page-size') || 100),
      // size: 100,
      checked: [],
      status: {
        folders: false,
        form: false,
        overlay: false,
        result: false,
        export: false,
        jsrq: false
      },
      importMode: 'default',
      overlay: {
        formParams: config.getOverlayFormParams ? config.getOverlayFormParams('default') : [],
        formData: config.getOverlayDefault ? config.getOverlayDefault('default') : {}
      },
      resultFormData: {},
      jsrqFormData: {},
      exportData: {
        cyrq: [new XDate(), new XDate().compute('d', 1)]
      },
      orgList: []
    }
  },
  computed: {
    dateOptions () {
      return { shortcuts }
    },
    sysEnv () {
      return sysEnv
    },
    formParams () {
      const params = config.getFormParams()
      return this.$authFunsProxy.result ? params : params.filter(v => v.key !== 'result')
    },
    importModes () {
      const modes = config.importModes || []
      if (this.$authFunsProxy.result) {
        return modes
      } else {
        return []
      }
    },
    resultFormParams () {
      const cyddList = this.cyddList
      const ids = this.resultFormData.ids
      const forms = typeof config.getUpdateFormParams === 'function' ? config.getUpdateFormParams() : []
      return [
        { label: '条件设置', type: 'split' },
        { label: '样本编号', key: 'ybid', type: 'input', group: 'condition', default: null },
        { label: '采样时间', key: 'cyrq', type: 'date', group: 'condition', default: null },
        {
          key: 'cydd', label: '采样单位', type: 'select', group: 'condition', default: null, options: cyddList.map(v => {
            return { label: v, value: v }
          })
        },
        {
          key: 'nullJsrq', label: '是否已接收', type: 'radio', group: 'condition', default: '0', radios: [
            { label: '已接收', value: 0 },
            { label: '未接收', value: 1 }
          ]
        },
        { label: '结果设置', type: 'split' },
        ...forms,
        {
          key: 'result', label: '检测结果', type: 'radio', default: '阴性', radios: [
            { label: '阳性', value: '阳性' },
            { label: '阴性', value: '阴性' }
          ]
        },
        {
          key: 'cover', label: '是否覆盖', type: 'radio', default: 0, radios: [
            { label: '是', value: 1 },
            { label: '否', value: 0 }
          ]
        }
      ].filter(v => !ids || (v.key && v.group !== 'condition'))
    },
    jsrqFormParams () {
      const cyddList = this.cyddList
      const ids = this.jsrqFormData.ids
      return [
        { label: '条件设置', type: 'split' },
        { label: '样本编号', key: 'ybid', type: 'input', group: 'condition', default: null },
        { label: '采样时间', key: 'cyrq', type: 'date', group: 'condition', default: null },
        {
          key: 'cydd', label: '采样单位', type: 'select', group: 'condition', default: null, options: cyddList.map(v => {
            return { label: v, value: v }
          })
        },
        { label: '结果设置', type: 'split' },
        { label: '接收日期', key: 'jsrq', type: 'datetime', required: true, default: null },
        {
          key: 'cover', label: '是否覆盖', type: 'radio', default: 0, radios: [
            { label: '是', value: 1 },
            { label: '否', value: 0 }
          ]
        }
      ].filter(v => !ids || (v.key && v.group !== 'condition'))
    }
  },
  methods: {
    async confirmExportData () {
      this.loading.export = true

      const parm = {
        pageNum: 1,
        pageSize: 99999999,
        minCyrq: this.$datetime.format(this.exportData.cyrq[0], 'Y-M-D') + ' 00:00:00',
        maxCyrq: this.$datetime.format(this.exportData.cyrq[1], 'Y-M-D') + ' 00:00:00'
      }

      const res = await nucleicReportRequest.get(parm)

      res.data.forEach(v => {
        let json = v.jsonStr ? JSON.parse(v.jsonStr) : {}
        v.age = v.age ? Number(v.age) : v.age
        Object.assign(v, json)
      })

      const dataList = res.data

      const dateFormat = (value) => {
        return value ? this.$datetime.format(value, 'Y-M-D') : ''
      }

      const dateTimeFormat = (value) => {
        return value ? this.$datetime.format(value, 'Y-M-D H:I') : ''
      }

      const fields = [
        { label: '序号', key: 'index' },
        { label: '样品编号', key: 'ybid' },
        { label: '患者姓名', key: 'name' },
        { label: '人员类别', key: 'rylb' },
        { label: '性别', key: 'gender' },
        { label: '年龄', key: 'age' },
        { label: '身份证号码', key: 'idno' },
        { label: '地址', key: 'address' },
        { label: '样本类型', key: 'yblx' },
        { label: '采样单位', key: 'cydd' },
        { label: '采样日期', key: 'cyrq', format: dateFormat },
        { label: '送样日期', key: 'syrq', format: dateFormat },
        { label: '采样人', key: 'cyr' },
        { label: '核酸检测机构名称', key: 'jcjg' },
        { label: '备注', key: 'remark'},
        { label: '结果', key: 'result' },
        { label: '结果发布时间', key: 'jcsj', format: dateTimeFormat }
      ]

      const workbook = createWorkbook()
      const worksheet = aoaToSheet([
        ['太湖县核酸检测送检情况表', ...colspan(fields.length - 1)],
        // ['单位名称：太湖县疾病预防控制中心', ...colspan(5), '填报时间：2021.09.02', ...colspan(9)],
        ["人员类别：1.密接、次密切接触者，包括中高风险地区来返人员，2.境外入境人员，3.发热门诊的患者，4.住院患者及陪护人员，5.医疗机构工作人员，6.口岸检疫边防检查人员，7.监所工作人员，8.社会福利养老机构工作人员，9.进口冷链食品从业人员，10.口岸直接接触进口货物从业人员，11.隔离场所工作人员，12.春运服务保障人员，13.农贸市场工作人员，14.快递外卖服务人员，15.交通运输服务人员，16.国际交通运输工具从业人员，17.船舶引航、登临外籍船舶作业人员，18.移民海关一线作业人员，19.市场监管系统一线工作人员，20.口岸高风险岗位人员的家属，21其它（需备注）\n样本类型：鼻咽拭子、咽拭子、痰样、肛拭子、血清\n样本编号：同一混采管的所有人使用同一编号", ...colspan(fields.length - 1)],
        fields.map(v => v.label),
        ...dataList.map((item, index) => {
          return fields.map((v) => {
            if (v.key === 'index') {
              return index
            } else if (typeof v.format === 'function') {
              return v.format(item[v.key])
            } else {
               return item[v.key]
            }
          })
        })
      ])

      worksheet['!merges'] = [
        ...new Colspan([[0, fields.length - 1]], 0),
        // ...new Colspan([[0, 5], [6, 15]], 1),
        ...new Colspan([[0, fields.length - 1]], 1),
      ]

      worksheet['!rows'] = {
        1: { hpx: 90 }
      }

      bookAppendSheet(workbook, worksheet, this.$datetime.format(this.exportData.cyrq[0], 'Y-M-D') + '至' + this.$datetime.format(this.exportData.cyrq[1], 'Y-M-D'))

      const emptyBorder = {
        top: {style: 'thin', color: {rgb: 'FFFFFF'}},
        bottom:	{style: 'thin', color: {rgb: 'FFFFFF'}},
        left:	{style: 'thin', color: {rgb: 'FFFFFF'}},
        right: {style: 'thin', color: {rgb: 'FFFFFF'}}
      }
      const blackBorder = {
        top: {style: 'thin', color: {rgb: '000000'}},
        bottom:	{style: 'thin', color: {rgb: '000000'}},
        left:	{style: 'thin', color: {rgb: '000000'}},
        right: {style: 'thin', color: {rgb: '000000'}}
      }

      // 设置表格基础样式
      setWorkbookBaseStyle(workbook, {
        font: {
          name: '宋体',
          sz: 10
        }
      })

      // 表头
      setWorksheetCellStyle(worksheet, [
        ...new Array(fields.length).fill(null).map((v, i) => [0, i])
      ], {
        font: {
          bold: true,
          name: '宋体',
          sz: 22
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true
        },
        border: emptyBorder
      })

      // 单位名称和填报时间
      // setWorksheetCellStyle(worksheet, [
      //   ...new Array(fields.length).fill(null).map((v, i) => [1, i])
      // ], {
      //   font: {
      //     bold: true,
      //     name: '宋体',
      //     sz: 12
      //   },
      //   alignment: {
      //     horizontal: 'left',
      //     vertical: 'center',
      //     wrapText: true
      //   },
      //   border: emptyBorder
      // })

      // 人员类别
      setWorksheetCellStyle(worksheet, [
        ...new Array(fields.length).fill(null).map((v, i) => [1, i])
      ], {
        font: {
          name: '宋体',
          sz: 10
        },
        alignment: {
          horizontal: 'left',
          vertical: 'center',
          wrapText: true
        },
        border: emptyBorder
      })

      // 字段名加粗
      fields.forEach((v, i) => {
        setWorksheetCellStyle(worksheet, [[2, i]], {
          font: {
            bold: true,
            name: '宋体',
            sz: 12
          },
          border: blackBorder
        })
      })

      downloadExcelWithStyle(workbook)
      this.loading.export = false
      this.status.export = false
    },
    itemChecked (i) {
      const index = this.checked.findIndex(v => v === i)
      if (index > -1) {
        this.checked.splice(index, 1)
      } else {
        this.checked.push(i)
      }
    },
    setResultUpdate () {
      this.openResultUpdate()
      this.$set(this.resultFormData, 'ids', this.checked.map(v => this.dataList[v].id).join(','))
    },
    getDefaultResultData () {
      let obj = typeof config.getUpdateDefault === 'function' ? config.getUpdateDefault() : {}
      this.resultFormParams.forEach(v => v.key && (obj[v.key] = v.default))
      return obj
    },
    openResultUpdate () {
      this.resultFormData = this.getDefaultResultData()
      this.status.result = true
    },
    setJsrqUpdate () {
      this.openJsrqUpdate()
      this.$set(this.jsrqFormData, 'ids', this.checked.map(v => this.dataList[v].id).join(','))
    },
    getDefaultJsrqData () {
      let obj = typeof config.getJsrqDefault === 'function' ? config.getJsrqDefault() : {}
      this.jsrqFormParams.forEach(v => v.key && (obj[v.key] = v.default))
      return obj
    },
    openJsrqUpdate () {
      this.jsrqFormData = this.getDefaultJsrqData()
      this.status.jsrq = true
    },
    async jsrqUpdate () {
      if (!this.jsrqFormData.jsrq) {
        return this.$notice.warning('请选择接收日期'), false
      }
      const data = {
        cover: this.jsrqFormData.cover,
        jsrq: this.$datetime.format(this.jsrqFormData.jsrq, 'Y-M-D H:I:S')
      }

      if (this.jsrqFormData.cyrq) {
        data.minCyrq = this.jsrqFormData.cyrq.format('Y-M-D')
        data.maxCyrq = this.jsrqFormData.cyrq.compute('D', 1).format('Y-M-D')
      }

      if (this.jsrqFormData.ids) {
        data.idList = this.jsrqFormData.ids
      }

      if (this.jsrqFormData.ybid) {
        data.ybid = this.jsrqFormData.ybid
      }

      if (this.jsrqFormData.cydd) {
        data.cydd = this.jsrqFormData.cydd
      }

      if (Object.keys(data).length <= 2) {
        this.$notice.error({ title: '更新提示', desc: '更新条件不足，请选择' })
      } else {
        const forms = typeof config.getJsrqFormParams === 'function' ? config.getJsrqFormParams() : []
        if (forms.length) {
          const checkEmpty = forms.filter(v => v.required && !this.jsrqFormData[v.key])
          if (checkEmpty.length) {
            return this.$notice.error({ title: '更新提示', desc: checkEmpty.map(v => v.label).join('，') + '不能为空' }), false
          }
          forms.forEach(v => {
            if (typeof v.format === 'function') {
              data[v.key] = v.format(this.jsrqFormData[v.key])
            } else {
              data[v.key] = this.jsrqFormData[v.key]
            }
          })
        }

        const confirm = await this.$dialog.confirm({ title: '更新提醒', content: '<span style="color: red;">满足条件的数据都将被更新，请确认是否更新！</span>' })
        if (confirm) {
          try {
            this.loading.jsrq = true
            const total = await nucleicReportRequest.jsrq(data)
            this.$notice.success({ title: '更新成功', desc: '共更新' + (isNaN(total) ? 0 : total) + '条数据' })
            this.loading.jsrq = false
            this.status.jsrq = false
            this.loadData()
          } catch (e) {
            this.loading.jsrq = false
          }
        }
      }
    },
    async resultUpdate () {
      const data = {
        nullJsrq: this.resultFormData.nullJsrq,
        cover: this.resultFormData.cover,
        result: this.resultFormData.result
      }

      if (this.resultFormData.cyrq) {
        data.minCyrq = this.resultFormData.cyrq.format('Y-M-D')
        data.maxCyrq = this.resultFormData.cyrq.compute('D', 1).format('Y-M-D')
      }

      if (this.resultFormData.ids) {
        data.idList = this.resultFormData.ids
      }

      if (this.resultFormData.ybid) {
        data.ybid = this.resultFormData.ybid
      }

      if (this.resultFormData.cydd) {
        data.cydd = this.resultFormData.cydd
      }

      if (Object.keys(data).length <= 3) {
        this.$notice.error({ title: '更新提示', desc: '更新条件不足，请选择' })
      } else {
        const forms = typeof config.getUpdateFormParams === 'function' ? config.getUpdateFormParams() : []
        if (forms.length) {
          const checkEmpty = forms.filter(v => v.required && !this.resultFormData[v.key])
          if (checkEmpty.length) {
            return this.$notice.error({ title: '更新提示', desc: checkEmpty.map(v => v.label).join('，') + '不能为空' }), false
          }
          forms.forEach(v => {
            if (typeof v.format === 'function') {
              data[v.key] = v.format(this.resultFormData[v.key])
            } else {
              data[v.key] = this.resultFormData[v.key]
            }
          })
        }

        const confirm = await this.$dialog.confirm({ title: '更新提醒', content: '<span style="color: red;">满足条件的数据都将被更新，请确认是否更新！</span>' })
        if (confirm) {
          this.loading.result = true
          try {
            const total = await nucleicReportRequest.update(data, 'result')
            this.loading.result = false
            this.$notice.success({ title: '更新成功', desc: '共更新' + (isNaN(total) ? 0 : total) + '条数据' })
            this.status.result = false
            this.loadData()
          } catch (e) {
            this.loading.result = false
          }
        }
      }
    },
    async loadCydd () {
      this.loading.cydd = true
      const res = await nucleicReportRequest.cydd()
      this.cyddList = res.filter(v => v).map(v => v.cydd)
      this.loading.cydd = false
    },
    async save () {
      try {
        if (!this.$authFunsProxy.update && !this.$authFunsProxy.result) {
          throw new Error('无权限')
        }

        let data = JSON.parse(JSON.stringify(this.chooseData, (key, value) => {
          if (['cyrq', 'syrq', 'jsrq', 'jcsj', 'bgsj'].includes(key)) {
            return value ? this.$datetime.format(value, 'Y-M-D H:I:S') : null
          } else {
            return value
          }
        }))

        if (!this.$authFunsProxy.result && data.result && data.id) {
          throw new Error('该数据已发布检测结果，不可修改')
        }

        typeof config.checkFormData === 'function' && config.checkFormData(data)

        let confirm = true
        if (this.orgList.length && config.checkOrgName !== false && !this.$authFunsProxy.result) {
          if (data.cydd !== this.orgList[0].name) {
            confirm = await this.$dialog.confirm({
              title: '导入警告',
              content: '<span style="color: orange; font-size: 20px;font-weight: 800;">采样单位名称和当前账号所属单位名称不匹配请修改导入表格！</span>',
              okText: '坚持导入',
              cancelText: '取消导入',
              wait: 8
            })
          }
        }
  
        if (confirm) {
          this.loading.load = true
          if (data.id) {
            await nucleicReportRequest.save(data.id, config.getDatas([data]).pop(), this.$authFunsProxy.result ? '' : 'no_result')
          } else {
            await nucleicReportRequest.update(config.getDatas([data]), this.$authFunsProxy.result ? '' : 'clear_result')
          }
          this.loading.load = false

          this.loadData()
          this.status.form = false

          this.$notice.success('成功')
        }

      } catch (error) {
        this.$notice.error({ title: '错误', desc: error.message })
      }
    },
    openEdit (data) {
      this.status.form = true
      this.chooseData = data
      orgRequest.get().then(list => this.orgList = list)
    },
    openForm () {
      this.status.form = true
      this.chooseData = config.getDefault()
      orgRequest.get().then(list => this.orgList = list)
    },
    async del () {
      if (this.$authFunsProxy.del) {
        const confirm = await this.$dialog.confirm({
          title: '删除提示',
          content: '确定删除' + this.checked.length + '条数据吗？'
        })
        if (confirm) {
          await nucleicReportRequest.del(this.checked.map(v => this.dataList[v].id))
          this.loadData()
        }
      }
    },
    async update (dataList) {
      console.log('update', dataList)
      if (this.$authFunsProxy.update || this.$authFunsProxy.result) {
        if (!dataList.length) {
          throw new Error('导入数据为空')
        }

        let promise = Promise.resolve(true)
        if (typeof config.getOverlayFormParams === 'function') {
          this.overlay.formParams = config.getOverlayFormParams(this.importMode)
          if (this.overlay.formParams.length) {
            this.overlay.formData = config.getOverlayDefault(this.importMode)
            this.status.overlay = true
            promise = new Promise((resolve, reject) => {
              overlayHook = { resolve, reject }
            })
          }
        }

        promise.then(async overlay => {
          this.status.overlay = false
          if (overlay !== true) {
            dataList.forEach(v => Object.assign(v, overlay))
          }

          const error = config.checkUpdate(dataList, this.importMode)
          if (error.list.length) {
            this.$notice.error({ title: '导入错误', desc: error.msg })
          } else {

            let confirm = await this.$dialog.confirm({
               title: '导入提醒',
               content: '<span style="color: red; font-size: 20px;font-weight: 800;">' + '请先核对是否重复录入，否则重复数据将产生覆盖或重复添加' + '</span>',
               okText: '确定导入',
               cancelText: '取消导入'
            })

            if (confirm && config.checkOrgName !== false && !this.$authFunsProxy.result) {
              const orgList = await orgRequest.get()
              if (orgList.length) {
                const diffOrg = dataList.findIndex(v => v.cydd !== orgList[0].name) > -1
                if (diffOrg) {
                  confirm = await this.$dialog.confirm({
                    title: '导入警告',
                    content: '<span style="color: orange; font-size: 20px;font-weight: 800;">采样单位名称和当前账号所属单位名称不匹配请修改导入表格！</span>',
                    okText: '坚持导入',
                    cancelText: '取消导入',
                    wait: 8
                  })
                }
              }
            }

            if (confirm) {
              this.loading.load = true
              const data = await nucleicReportRequest.update(config.getDatas(dataList), this.$authFunsProxy.result ? '' : 'clear_result')
              this.loading.load = false

              data.forEach(v => {
                let json = v.jsonStr ? JSON.parse(v.jsonStr) : {}
                v.age = v.age ? Number(v.age) : v.age
                Object.assign(v, json)
              })

              this.loadCydd()
      
              this.checked = []
              this.dataList = data
              this.$notice.success({
                title: '数据已更新',
                desc: '共计' + data.length + '条数据'
              })
            }
          }
        }).catch(err => {
          console.error(err)
          this.loading.load = false
          this.status.overlay = false
          this.$notice.error({ title: '提示', desc: err.message })
        })
      }
    },
    async loadData () {
      if (this.$authFunsProxy.get) {
        const parm = {
          pageNum: this.page,
          pageSize: this.size
        }
  
        if (this.search.value) {
          parm[this.search.type] = this.search.value
        }
  
        if (this.search.date) {
          parm.minJcsj = this.$datetime.format(this.search.date, 'Y-M-D')
          parm.maxJcsj = new this.$datetime.Date(this.search.date).compute('D', 1).format('Y-M-D')
        }

        if (this.search.cydd) {
          parm.cydd = this.search.cydd
        }

        if (this.search.cyrq) {
          if (this.search.cyrq.length >= 1 && this.search.cyrq[0]) {
            parm.minCyrq = this.$datetime.format(this.search.cyrq[0], 'Y-M-D') + ' 00:00:00'
          }
          if (this.search.cyrq.length >= 2 && this.search.cyrq[1]) {
            parm.maxCyrq = this.$datetime.format(this.search.cyrq[1], 'Y-M-D') + ' 00:00:00'
          }
        }

        if (this.search.result) {
          if (this.search.result === '未检测') {
            parm.nullResult = 1
          } else {
            parm.result = this.search.result
          }
        }

        if (this.search.nullJsrq) {
          parm.nullJsrq = this.search.nullJsrq
        }
  
        this.loading.get = true
        const res = await nucleicReportRequest.get(parm)
        this.loading.get = false

        res.data.forEach(v => {
          let json = v.jsonStr ? JSON.parse(v.jsonStr) : {}
          v.age = v.age ? Number(v.age) : v.age
          Object.assign(v, json)
        })

        this.total = res.total
        this.checked = []
        this.dataList = res.data
        this.$nextTick(() => {
          this.$refs.list.scrollTop = 0
        })
      }
    },
    pageChange (page) {
      this.page = page
      this.loadData()
    },
    pageSizeChange () {
      localStorage.setItem('report-nucleic-page-size', String(this.size))
      this.page = 1
      this.loadData()
    },
    onSearch () {
      this.page = 1
      this.loadData()
    },
    overlayUpdate () {
      const res = config.checkOverlay(this.overlay.formData, this.importMode)
      if (res !== true) {
        overlayHook.reject(new Error(res))
      } else {
        overlayHook.resolve(this.overlay.formData)
      }
    },
    async print () {
      const noResult = this.checked.filter(v => !this.dataList[v].result)

      if (noResult.length) {
        this.$notice.warning({
          title: '报告生成提示',
          desc: '所选报告（共' + noResult.length + '份）还未发布检测结果，请等待疾控中心发布结果后再打印报告。谢谢！',
          duration: 8000
        })
      } else {
        if (this.checked.length > 100) {
          const confirm = await this.$dialog.confirm({
            title: '打印提示',
            content: '为减少等待时间，每页报告条数不建议超过100条，请调整分页',
            okText: '调整为100条每页',
            cancelText: '取消'
          })
          if (confirm) {
            this.size = 100
            this.pageSizeChange()
          }
          return false
        }
        const html = this.checked.map(v => {
          return config.renderItem(this.dataList[v], signature)
        }).join('<div style="page-break-after: always;"></div>')
  
        // eslint-disable-next-line no-useless-escape
        this.$refs.iframe.srcdoc = '<!DOCTYPE html><head><link rel="stylesheet" href="/static/document/nucleic.css"></head><body>' + html + "</body><script>document.addEventListener('DOMContentLoaded', function () {setTimeout(function () {window.print();}, 0);});<\/script></html>"
      }
    },
    checkAll () {
      this.checked = this.dataList.map((v, i) => i)
    },
    checkReverse () {
      this.checked = this.dataList.map((v, i) => i).filter(v => {
        return !this.checked.includes(v)
      })
    },
    async fileDrop (event) {
      this.$refs.area.setAttribute('status', '解析中...')
      event.preventDefault()
      let files = event.dataTransfer.files;
      if (files.length < 1)
          return;
      await this.readFile(files)
      this.$refs.area.setAttribute('status', '解析成功')
      this.dragenter = false
    },
    onDragenter (e) {
      this.$refs.area.setAttribute('status', '此处松开鼠标左键释放文件即可')
      e.preventDefault()
      this.dragenter = true
    },
    onDragover (e) {
      e.preventDefault()
    },
    onDragend (e) {
      this.$refs.area.setAttribute('status', '此处松开鼠标左键释放文件即可')
      e.preventDefault()
      this.dragenter = false
    },
    transData (workbooks) {
      return workbooks.map(workbook => {
        return config.transData(XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
          blankrows: true, raw: false, defval: null
        }), this.importMode)
      })
    },
    async readFile (files) {
      try {
        this.loading.load = true
        files = files || this.$refs.input.files
        const promise = ([...files]).filter(file => {
          return isExcel(file.name)
        }).map(file => {
          return new Promise(resolve => {
            var reader = new FileReader()
            reader.onload = (e) => resolve(XLSX.read(e.target.result, {
              type: 'binary',
              cellDates: true
            }))
            reader.readAsBinaryString(file)
          })
        })

        this.checked = []
        this.update(this.transData(await Promise.all(promise)).flat())

        this.loading.load = false
      } catch (error) {
        console.error(error)
        this.$notice.error({ title: '错误', desc: error.message })
        this.loading.load = false
      }
    }
  },
  async mounted () {
    this.importMode = 'default'
    if (!signature.length && this.$authFunsProxy.print) {
      signature = await Promise.all(config.getSignData().map(async item => {
        const data = await fileRequest.download({ path: item.path })
        item.src = window.URL.createObjectURL(new Blob([data]))
        return item
      }))
    }
    this.loadCydd()
    this.loadData()
  }
}
</script>
